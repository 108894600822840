import { Fragment, useState } from "react";
import styles from "./RadioButton.module.css";

const RadioButton = (props) => {
  const onHovering = () => {
    props.setHoverIndex(props.index);
  };

  const isLink = (str) => {
    const pattern = /^#(https?:\/\/)?([a-zA-Z0-9.-]+)?\.[a-z]{2,6}(\/[^\s]*)?$/;
    console.log(pattern.test(str));
    return pattern.test(str);
  };

  return (
    <Fragment>
      {props.color && (
        <>
          {!isLink(props.color) ? (
            <div
              onMouseEnter={onHovering}
              className={`${styles.wrapper} ${
                styles[props.active && `active`]
              }`}
              onClick={props.onClick}
              index={props.index}
              style={{ backgroundColor: props.color }}
            />
          ) : (
            <div
              onMouseEnter={onHovering}
              className={`${styles.wrapper} ${
                styles[props.active && `active`]
              }`}
              onClick={props.onClick}
              index={props.index}
              style={{ backgroundColor: props.color }}
            >
              <img src={props.color.slice(1)} className={styles.image} />
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default RadioButton;
