import { useState, Fragment, useEffect, useContext } from "react";
import styles from "./ItemList.module.css";
import { Outlet, useParams } from "react-router-dom";
import CartContext from "../store/cart-context";
import ItemListHeader from "./Header/ItemListHeader";
import ItemListMain from "./Main/ItemListMain";
import CategoryContext from "../store/category-context";
import ListActions from "./Main/ListActions";
import useGetItems from "../hooks/use-get-items";
import SubcategoryList from "./Main/SubcategoryList";
import SearchHeader from "./Header/SearchHeader";

const ItemList = () => {
  const cartCtx = useContext(CartContext);
  const categoryCtx = useContext(CategoryContext);
  let params = useParams().categorySlug;
  let searchParams = useParams().searchTerm;

  const [sortingValue, setSortingValue] = useState("Price Up");
  const [gridStyleValue, setGridStyleValue] = useState("0");

  const [currentCategory, setCurrentCategory] = useState(
    params !== undefined ? categoryCtx.getCategory(params) : false
  );

  const [subcategories, setSubCategories] = useState(
    categoryCtx.categories &&
      currentCategory &&
      categoryCtx.categories.length > 0 &&
      categoryCtx.categories.filter(
        (el) => el.parent && el.parent.node.id == currentCategory.id
      )
  );

  const [itemList, setItemList] = useState();

  //const [itemList, setItemList, getItemList] = useGetItems();
  const { data, pageInfo, loading, error, setCursor } = useGetItems(
    params,
    searchParams,
    setItemList
  );
  const [filteredItemList, setFilteredItemList] = useState([]);

  useEffect(() => {
    setItemList();
  }, [params, searchParams]);

  useEffect(() => {
    //setItemList(data);
    if (data) {
      if (itemList) {
        setItemList((prevValue) => [...itemList, ...data]);
      } else {
        setItemList(data);
      }
    }
  }, [data]);

  const changeGridStyleValue = (val) => {
    setGridStyleValue(val);
  };

  useEffect(() => {
    if (categoryCtx && params !== undefined) {
      searchParams = undefined;
      setCurrentCategory(categoryCtx.getCategory(params));
    }
  }, [categoryCtx, params]);

  useEffect(() => {
    if (currentCategory && currentCategory.id) {
      setSubCategories(
        categoryCtx.categories.filter(
          (el) => el.parent && el.parent.node.id == currentCategory.id
        )
      );
    }
  }, [currentCategory, currentCategory && currentCategory.id]);

  /*useEffect(() => {
    console.log("REFECH");

    //&category=${currentCategory.id}
    if (params !== undefined) {
      searchParams = undefined;
      if (currentCategory && currentCategory.id) {
        setItemList([]);
        getItemList(
          `?per_page=10&consumer_key=ck_a270e588788fe749560568f37f4d9ab9663f48ca&consumer_secret=cs_892dc7028829da5c035079fd9e64da11a9ac9bc4`
        );
      }
    } else if (searchParams !== undefined) {
      params = undefined;
      setCurrentCategory(false);
      setItemList([]);
      getItemList(
        `?${
          searchParams !== "*" ? `search=${searchParams}&` : ""
        }&per_page=5&consumer_key=ck_a270e588788fe749560568f37f4d9ab9663f48ca&consumer_secret=cs_892dc7028829da5c035079fd9e64da11a9ac9bc4`
      );
    }
  }, [currentCategory, cartCtx.items.length, searchParams]);*/

  /*useEffect(() => {
    let temp = [...itemList];
    setFilteredItemList([]);
    setTimeout(() => {
      if (sortingValue === "Price Up") {
        temp = temp.sort((a, b) => a.price - b.price);
      } else if (sortingValue === "Price Down") {
        temp = temp.sort((a, b) => b.price - a.price);
      } else {
        temp = temp.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
      setItemList(temp);
    }, [1]);
  }, [sortingValue]);*/

  return (
    <Fragment>
      <Outlet />
      <div className={styles.wrapper}>
        {categoryCtx.categories && (
          <Fragment>
            {searchParams && <SearchHeader searchParams={searchParams} />}
            {!searchParams && (
              <>
                <ItemListHeader category={currentCategory} params={params} />
                <SubcategoryList subcategories={subcategories} />
                <div className={styles.count}>
                  {currentCategory ? currentCategory.count : 0} proizvoda
                </div>
              </>
            )}
            {/*<ListActions
              itemList={itemList}
              //setFilteredItemList={setFilteredItemList}
              sortingValue={sortingValue}
              setSortingValue={setSortingValue}
              sty={changeGridStyleValue}
            />*/}
            <ItemListMain
              gridStyle={gridStyleValue}
              itemInfo={itemList}
              loading={loading}
              error={error}
              currentCategory={params}
              categories={categoryCtx.categories}
            />
            {pageInfo.hasNextPage && (
              <div className={styles[`load-more-container`]}>
                <div
                  className={styles[`load-more-button`]}
                  onClick={() => {
                    setCursor(pageInfo.endCursor);
                  }}
                >
                  Učitaj više.
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

{
  /*itemList !== undefined && (
  <ItemListMain
    gridStyle={gridStyleValue}
    itemInfo={filteredItemList}
    itemListCount={itemList.length}
    currentCategory={params}
    categories={categoryCtx.categories}
  />
)*/
}

export default ItemList;
