import styles from "./Item.module.css";

import { Fragment, useContext, useEffect, useState } from "react";
import CartContext from "../../store/cart-context";
import ItemInfo from "./ItemInfo";
import ItemSelection from "./ItemSelection";
import ImagePortfolioSection from "./ImagePortfolioSction";
import ToBasketSection from "./ToBasketSection";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ItemDescription from "./ItemDescription";
import SimilarProducts from "./SimilarProducts";
import LoadingAnimation from "../../UI/LoadingAnimation";
import { useRef } from "react";
import { Link } from "react-router-dom";
import useGetItem from "../../hooks/use-get-item";
import Footer2 from "../../Informative-Pages/Footer2";
import useScrollStop from "../../hooks/use-scroll-stop";

const Item = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const topRef = useRef(null);

  const categorySlug = useParams().categorySlug;
  const itemSlug = useParams().productSlug;
  const itemInfo = location.state;
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [newSelectedColorIndex, setNewSelectedColorIndex] = useState();

  useEffect(() => {
    console.log(newSelectedColorIndex);
  }, [newSelectedColorIndex]);

  const [item, loading, error] = useGetItem(itemSlug, itemInfo !== undefined);
  const [forBasket, setForBasket] = useState([]);

  useScrollStop();

  const scrollToTop = () => {
    topRef.current.scroll({
      top: 0,
      behavior: "instant",
    });
  };

  const onCloseHandler = () => {
    navigate(`/${categorySlug}`);
  };

  useEffect(() => {
    scrollToTop();
  }, [location]);

  const info = (item) => {
    return (
      <Fragment>
        <div className={styles[`item-main`]}>
          <ImagePortfolioSection
            item={item}
            selectedColorIndex={selectedColorIndex}
            newSelectedColorIndex={newSelectedColorIndex}
          />
          <div className={styles[`information-wrapper`]}>
            <div className={styles.path}>
              <Link to="/" className={styles.past}>
                Naslovnica
              </Link>
              <div> / </div>
              {item.productCategories && (
                <Link
                  to={`/${item.productCategories.nodes[0].slug}`}
                  className={styles.past}
                >
                  {item.productCategories.nodes[0].name}
                </Link>
              )}
              <div> / </div>
              <div>
                <strong>{item.name}</strong>
              </div>
            </div>

            <div className={styles[`info-division`]}>
              <div className={styles[`info-section`]}>
                <ItemInfo itemInfo={item} />
              </div>
              <div className={styles[`info-section`]}>
                {item.attributes && (
                  <ItemSelection
                    itemSlug={itemSlug}
                    selectedColorIndex={selectedColorIndex}
                    newSelectedColorIndex={newSelectedColorIndex}
                    setNewSelectedColorIndex={setNewSelectedColorIndex}
                    colorIndex={selectedColorIndex}
                    setColorIndex={setSelectedColorIndex}
                    forBasket={forBasket}
                    setForBasket={setForBasket}
                    size={
                      item.attributes.nodes &&
                      item.attributes.nodes[
                        item.attributes.nodes.findIndex(
                          (e) => e.name === "pa_size"
                        )
                      ]
                        ? item.attributes.nodes[
                            item.attributes.nodes.findIndex(
                              (e) => e.name === "pa_size"
                            )
                          ]
                        : undefined
                    }
                    newColors={item.attributes.nodes && item.attributes.nodes}
                    color={
                      item.attributes.nodes &&
                      item.attributes.nodes[
                        item.attributes.nodes.findIndex(
                          (e) => e.name === "pa_color"
                        )
                      ]
                        ? item.attributes.nodes[
                            item.attributes.nodes.findIndex(
                              (e) => e.name === "pa_color"
                            )
                          ]
                        : undefined
                    }
                  />
                )}
              </div>
            </div>
            <ToBasketSection
              item={item}
              forBasket={forBasket}
              setForBasket={setForBasket}
            />
          </div>
        </div>
        <ItemDescription item={item} />
        {item && item !== undefined && (
          <SimilarProducts currentCategory={categorySlug} />
        )}
        <Footer2 />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className={styles.wrapper} ref={topRef}>
        {itemInfo && info(itemInfo.item)}
      </div>
    </Fragment>
  );
};

export default Item;

/* {(!itemInfo || error) && <div>ERROR</div>}
        {(!itemInfo || loading) && <LoadingAnimation />}
        {(item || itemInfo) && (
          <Fragment>
            <div className={styles[`item-main`]}>
              <ImagePortfolioSection
                item={item}
                selectedColorIndex={selectedColorIndex}
              />
              <div className={styles[`information-wrapper`]}>
                <div className={styles.path}>
                  <Link to="/" className={styles.past}>
                    Naslovnica
                  </Link>
                  <div> / </div>
                  <Link
                    to={`/${item.productCategories.nodes[0].slug}`}
                    className={styles.past}
                  >
                    {item.productCategories.nodes[0].name}
                  </Link>
                  <div> / </div>
                  <div>
                    <strong>{item.name}</strong>
                  </div>
                </div>

                <div className={styles[`info-division`]}>
                  <div className={styles[`info-section`]}>
                    <ItemInfo itemInfo={item} />
                  </div>
                  <div className={styles[`info-section`]}>
                    {
                      <ItemSelection
                        itemSlug={itemSlug}
                        selectedColorIndex={selectedColorIndex}
                        setColorIndex={setSelectedColorIndex}
                        itemVariations={item || undefined}
                        setItemVariations={item}
                        size={
                          item.attributes.nodes &&
                          item.attributes.nodes[
                            item.attributes.nodes.findIndex(
                              (e) => e.name === "pa_size"
                            )
                          ]
                            ? item.attributes.nodes[
                                item.attributes.nodes.findIndex(
                                  (e) => e.name === "pa_size"
                                )
                              ]
                            : undefined
                        }
                        color={
                          item.attributes.nodes &&
                          item.attributes.nodes[
                            item.attributes.nodes.findIndex(
                              (e) => e.name === "pa_color"
                            )
                          ]
                            ? item.attributes.nodes[
                                item.attributes.nodes.findIndex(
                                  (e) => e.name === "pa_color"
                                )
                              ].terms.nodes
                            : undefined
                        }
                      />
                    }
                  </div>
                </div>
                {/*<ToBasketSection
                  item={item}
                  itemVariations={itemVariations}
                  setItemVariations={setItemVariations}
                />}
              </div>
            </div>
            {/*<ItemDescription item={item} />
            <SimilarProducts currentCategory={categorySlug} />
            <Footer2 />}
          </Fragment>
        )}*/
